<template>
  <div class="ff-open-sans test-report">
    <div v-if="displayResult">
      <div class="flex flex-between items-center">
        <h1 class="fs-16 fw-600" v-if="this.type !== 4">
          Test /
          <span class="primary-text" v-if="this.type === 1">Student Test Summary</span>
          <span class="primary-text" v-if="this.type === 2">Institute Test Summary</span>
          <span class="primary-text" v-if="this.type === 3">Shared Test Summary</span>
        </h1>
        <h1 class="fs-16 fw-600" v-else>
          Assignment / <span class="primary-text">Assignment Summary</span>
        </h1>
        <img
          :src="require(`../../assets/close-img.svg`)"
          alt="close"
          class="pointer"
          style="margin-top: 8px; margin-right: -16px"
          @click="goBackToPrevious()"
        />
      </div>
      <div class="report-top flex">
        <div class="top-left">
          <div class="left-result">
            <div class="result-box">
              <p class="fs-16 fw-600 white-text">Score</p>
              <p
                class="white-text score"
                v-if="
                  sessionSummary?.MarksEarned !== null && Object.keys(testReportList).length > 0
                "
              >
                <span class="fs-42 fw-700">{{ sessionSummary?.MarksEarned }}</span>
                <img :src="require(`../../assets/slash.svg`)" alt="" />
                <span class="fs-36 fw-400">{{ sessionSummary?.TotalMarks }}</span>
              </p>
              <p class="white-text score fs-36 fw-400" v-else>N/A</p>
            </div>
            <div class="result-box">
              <p class="fs-16 fw-600 white-text">Attempts</p>
              <p
                class="white-text score"
                v-if="
                  sessionSummary?.TotalAttempted !== null && Object.keys(testReportList).length > 0
                "
              >
                <span class="fs-42 fw-700">{{ sessionSummary?.TotalAttempted }}</span>
                <img :src="require(`../../assets/slash.svg`)" alt="" />
                <span class="fs-36 fw-400">{{ sessionSummary?.TotalQuestions }}</span>
              </p>
              <p class="white-text score fs-36 fw-400" v-else>N/A</p>
            </div>
            <div class="result-box">
              <p class="fs-16 fw-600 white-text">Correct</p>
              <p
                class="white-text score"
                v-if="
                  sessionSummary?.TotalCorrect !== null && Object.keys(testReportList).length > 0
                "
              >
                <span class="fs-42 fw-700">{{ sessionSummary?.TotalCorrect }}</span>
                <img :src="require(`../../assets/slash.svg`)" alt="" />
                <span class="fs-36 fw-400">{{ sessionSummary?.TotalAttempted }}</span>
              </p>
              <p class="white-text score fs-36 fw-400" v-else>N/A</p>
            </div>
          </div>
          <div class="right-accuracy white relative">
            <h2 class="fs-16 fw-500 top-header">Accuracy Breakdown</h2>
            <div class="flex flex-between" style="margin-top: 37px">
              <div style="margin-left: 20px">
                <p class="fs-13 fw-700 dark-text accuracy-detail relative">
                  Correct: <span>{{ sessionSummary?.TotalCorrect }}</span>
                </p>
                <p class="fs-13 fw-700 dark-text accuracy-detail relative">
                  Incorrect: <span>{{ sessionSummary?.TotalWrong }}</span>
                </p>
                <p class="fs-13 fw-700 dark-text accuracy-detail relative">
                  Skipped: <span>{{ sessionSummary?.TotalSkipped }}</span>
                </p>
              </div>
              <div class="donut-box">
                <vc-donut
                  :sections="sections"
                  :thickness="30"
                  :size="223"
                  :unit="'px'"
                  class="vc-cdc"
                >
                  <div class="donut-content">
                    <div>
                      <p class="fs-10 fw-400 dark-text">
                        Your Accuracy <br />
                        <span
                          class="fw-600 fs-26 skyblue-text"
                          v-if="
                            sessionSummary?.Accuracy !== null &&
                            Object.keys(testReportList).length > 0
                          "
                          >{{ sessionSummary?.Accuracy }}
                          <span class="fs-10" style="margin-left: -6px">%</span></span
                        ><span class="fs-10 fw-400 skyblue-text" v-else>N/A</span>
                      </p>
                    </div>
                  </div>
                </vc-donut>
                <p
                  class="fs-15 fw-600 black-font PS-time flex items-center flex-center"
                  style="margin-top: 15px"
                >
                  <img :src="require(`../../assets/time-icon.svg`)" alt="" />
                  <span>{{
                    formatTime(sessionSummary?.AvgTime ? sessionSummary?.AvgTime : "0")
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="top-right white w-100">
          <h2 class="fs-16 fw-500 top-header">Subject Breakdown</h2>
          <div class="sub-detail ff-inter">
            <div class="sub-header">
              <p class="fs-15 fw-500 dark-text">Subject</p>
              <p class="fs-15 fw-500 dark-text">Score</p>
              <p class="fs-15 fw-500 dark-text">Accuracy</p>
            </div>
            <div class="scroll-verticle-bar breakdown-list" style="height: 255px">
              <div class="sub-content" v-for="(subject, index) in subjectBreakdown" :key="index">
                <p class="fs-16 fw-500 skyblue-text flex items-center">
                  <img :src="subject.Icon" alt="" style="margin-right: 8px; width: 30px" />
                  <span>{{ subject.SubjectName }}</span>
                </p>
                <div>
                  <p class="fs-16 fw-600 dark-text ff-inter">
                    {{ subject.MarksEarned }}/{{ subject.TotalScore }}
                  </p>
                  <div class="progress-bar">
                    <div
                      class="progress-meter"
                      :style="`width: ${(subject.MarksEarned / subject.TotalScore) * 100}%`"
                    ></div>
                  </div>
                </div>
                <circle-progress
                  :percent="subject.Accuracy"
                  :size="55"
                  :show-percent="true"
                  fill-color="#F7823C"
                  :border-width="5"
                  :border-bg-width="5"
                  empty-color="#EAEAEA"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- filters -->
      <div class="report-bottom">
        <div class="bottom-top flex white flex-wrap">
          <div
            class="flex items-start pointer relative qns-filter"
            :class="{
              'disable-filters': tempQnsList.length === 0,
              active: currentSelectedFilter === 1,
            }"
            @click="filterQnsList(1)"
          >
            <img
              :src="require(`../../assets/circle-qns.svg`)"
              alt=""
              style="margin-right: 11px; width: 30px"
            />
            <h3 class="fs-14">
              <p class="fs-14 fw-700 skyblue-light nowrap">Total Questions</p>
              <p class="fs-12 fw-600 skyblue-light">{{ tempQnsList.length }}</p>
            </h3>
          </div>
          <div
            class="flex items-start pointer relative qns-filter"
            :class="{
              'disable-filters': attemptedQnsCount === 0,
              active: currentSelectedFilter === 2,
            }"
            @click="filterQnsList(2)"
          >
            <img
              :src="require(`../../assets/total-attempt.svg`)"
              alt=""
              style="margin-right: 11px; width: 30px"
            />
            <h3 class="fs-14">
              <p class="fs-14 fw-700 skyblue-text">Attempted</p>
              <p class="fs-12 fw-600 skyblue-text">{{ attemptedQnsCount }}</p>
            </h3>
          </div>
          <div
            class="flex items-start pointer relative qns-filter"
            :class="{
              'disable-filters': correctQnsCount === 0,
              active: currentSelectedFilter === 3,
            }"
            @click="filterQnsList(3)"
          >
            <img
              :src="require(`../../assets/correct-qns.svg`)"
              alt=""
              style="margin-right: 11px; width: 30px"
            />
            <h3 class="fs-14">
              <p class="fs-14 fw-700" style="color: #16a085">Correct</p>
              <p class="fs-12 fw-600" style="color: #16a085">{{ correctQnsCount }}</p>
            </h3>
          </div>
          <div
            class="flex items-start pointer relative qns-filter"
            :class="{
              'disable-filters': inCorrectQnsCount === 0,
              active: currentSelectedFilter === 4,
            }"
            @click="filterQnsList(4)"
          >
            <img
              :src="require(`../../assets/wrong-qns.svg`)"
              alt=""
              style="margin-right: 11px; width: 30px"
            />
            <h3 class="fs-14">
              <p class="fs-14 fw-700" style="color: #ea7b7b">Incorrect</p>
              <p class="fs-12 fw-600" style="color: #ea7b7b">{{ inCorrectQnsCount }}</p>
            </h3>
          </div>
          <div
            class="flex items-start pointer relative qns-filter"
            :class="{
              'disable-filters': skippedQnsCount === 0,
              active: currentSelectedFilter === 5,
            }"
            @click="filterQnsList(5)"
          >
            <img
              :src="require(`../../assets/skip-qns.svg`)"
              alt=""
              style="margin-right: 11px; width: 30px"
            />
            <h3 class="fs-14">
              <p class="fs-14 fw-700" style="color: #8e8e8e">Skipped</p>
              <p class="fs-12 fw-600" style="color: #8e8e8e">{{ skippedQnsCount }}</p>
            </h3>
          </div>
        </div>
        <div
          class="report-filters flex"
          :class="{ 'disable-filters': Object.keys(testReportList).length === 0 }"
        >
          <div class="filter-section white" :class="{ 'open-filter': showFilterBox }">
            <h1
              class="fs-16 fw-700 dark-text flex flex-between items-center"
              style="padding: 15px 14px; border-bottom: 1px solid #e0e4f0"
            >
              <span> Filter</span>
              <span
                class="material-icons material-symbols-outlined red-text pointer show-mobile-mode"
                @click="showFilterBox = false"
                >cancel</span
              >
            </h1>
            <div class="filter">
              <h2
                class="fs-13 fw-700 dark-text flex flex-between pointer"
                @click="showQuestionFilter = !showQuestionFilter"
              >
                <span>Question</span>
                <span
                  ><i class="material-icons fs-15">{{
                    showQuestionFilter ? "expand_less" : "expand_more"
                  }}</i></span
                >
              </h2>
              <div class="options" v-if="showQuestionFilter">
                <p>
                  <label>
                    <input
                      class="with-gap"
                      name="group1"
                      type="radio"
                      value="-1"
                      :checked="selectedQnsFilterId === -1"
                      @click="filterByQns($event)"
                    />
                    <span class="fs-13">All</span>
                  </label>
                </p>
                <p
                  v-if="
                    correctQnsCount !== 0 &&
                    (currentSelectedFilter === 1 || currentSelectedFilter === 2)
                  "
                >
                  <label>
                    <input
                      class="with-gap"
                      name="group1"
                      type="radio"
                      :checked="selectedQnsFilterId === 1"
                      value="1"
                      @click="filterByQns($event)"
                    />
                    <span class="fs-13">Correct</span>
                  </label>
                </p>
                <p
                  v-if="
                    inCorrectQnsCount !== 0 &&
                    (currentSelectedFilter === 1 || currentSelectedFilter === 2)
                  "
                >
                  <label>
                    <input
                      class="with-gap"
                      name="group1"
                      type="radio"
                      value="2"
                      :checked="selectedQnsFilterId === 2"
                      @click="filterByQns($event)"
                    />
                    <span class="fs-13">Incorrect</span>
                  </label>
                </p>
                <p v-if="skippedQnsCount !== 0 && currentSelectedFilter === 1">
                  <label>
                    <input
                      class="with-gap"
                      name="group1"
                      type="radio"
                      value="3"
                      :checked="selectedQnsFilterId === 3"
                      @click="filterByQns($event)"
                    />
                    <span class="fs-13">Skipped</span>
                  </label>
                </p>
              </div>
            </div>
            <div class="filter">
              <h2
                class="fs-13 fw-700 dark-text flex flex-between pointer"
                @click="showSubFilter = !showSubFilter"
              >
                <span>Subject</span>
                <span
                  ><i class="material-icons fs-15">{{
                    showSubFilter ? "expand_less" : "expand_more"
                  }}</i></span
                >
              </h2>
              <div
                class="options scroll-verticle-bar"
                v-if="showSubFilter"
                style="max-height: 130px"
              >
                <p v-for="(subject, index) in filterSubjectList" :key="index">
                  <label>
                    <input
                      class="with-gap"
                      name="subject"
                      type="radio"
                      :checked="selectedSubjectId === subject.SubjectId"
                      :value="subject.SubjectId"
                      @change="filterBySubject($event)"
                    />
                    <span class="fs-13">{{ subject.SubjectName }}</span>
                  </label>
                </p>
              </div>
            </div>
            <div class="filter">
              <h2
                class="fs-13 fw-700 dark-text flex flex-between pointer"
                @click="showSortByFilter = !showSortByFilter"
              >
                <span>Sort by</span>
                <span
                  ><i class="material-icons fs-15">{{
                    showSortByFilter ? "expand_less" : "expand_more"
                  }}</i></span
                >
              </h2>
              <div class="options" v-if="showSortByFilter">
                <p>
                  <label>
                    <input
                      class="with-gap"
                      name="sort-qns"
                      type="radio"
                      :checked="sortedQnsId === -1"
                      :value="-1"
                      @change="sortQnsList($event)"
                    />
                    <span class="fs-13">Serial Number</span>
                  </label>
                </p>
                <p>
                  <label>
                    <input
                      class="with-gap"
                      name="sort-qns"
                      type="radio"
                      :checked="sortedQnsId === 1"
                      :value="1"
                      @change="sortQnsList($event)"
                    />
                    <span class="fs-13">Time Taken ( Low - High)</span>
                  </label>
                </p>
                <p>
                  <label>
                    <input
                      class="with-gap"
                      name="sort-qns"
                      type="radio"
                      :checked="sortedQnsId === 2"
                      :value="2"
                      @change="sortQnsList($event)"
                    />
                    <span class="fs-13">Time Taken ( High - Low)</span>
                  </label>
                </p>
              </div>
            </div>
          </div>
          <div class="content-section">
            <div>
              <div class="content-header">
                <p class="fs-13" style="color: #676767; margin-bottom: -5px">
                  <span
                    class="material-icons material-symbols-outlined skyblue-text show-mobile-mode filter-icon"
                    @click="oprnFilter()"
                    >filter_alt</span
                  >
                </p>
                <p class="fs-13" style="color: #676767">
                  <span class="show-desktop-mode">Question</span
                  ><span class="show-mobile-mode">Qns</span>
                </p>
                <p class="fs-13 answer-header" style="color: #676767; white-space: nowrap">
                  <span class="show-desktop-mode">Your Answer</span
                  ><span class="show-mobile-mode">Your Ans</span>
                </p>
                <p class="fs-13" style="color: #676767">Marks</p>
                <p class="fs-13" style="color: #676767">Time</p>
                <p class="fs-13 right-align" style="color: 676767">Action</p>
              </div>
            </div>
            <div class="content-body scroll-verticle" v-if="questionList.length > 0">
              <div
                class="contents white"
                v-for="(question, index) in questionList"
                :key="index"
                :class="question.DifficultyGroup ? question.DifficultyGroup.toLowerCase() : 'easy'"
              >
                <p class="fs-13 fw-600 black-text right-align">{{ index + 1 }}</p>
                <div class="qns-img" @click="showQns(question.QuestionDiagramURL)">
                  <img
                    :src="storage_Url + question.QuestionDiagramURL.replace('~', '')"
                    class="w-100 show-desktop-mode"
                  />
                  <p class="fs-13 fw-600 skyblue-text pointer flex items-center show-mobile-mode">
                    <span
                      class="material-icons material-symbols-outlined pointer view-icon"
                      style="margin-right: 6px"
                      >visibility</span
                    ><span>Qn.</span>
                  </p>
                </div>
                <p class="fs-13 fw-600 flex items-center" style="white-space: nowrap">
                  <img
                    :src="require(`../../assets/correct-qns.svg`)"
                    alt=""
                    style="margin-right: 12px"
                    v-if="question.Answer && question.IsCorrect === 1"
                  />
                  <img
                    :src="require(`../../assets/wrong-qns.svg`)"
                    alt=""
                    style="margin-right: 12px"
                    v-if="question.Answer && question.IsCorrect === 0"
                  />
                  <span>{{ question.Answer ? question.Answer : "-" }}</span>
                </p>
                <!-- <p class="fs-13 fw-600 black-text">{{question.MarksEarned}}/{{ question.MarksPerQuestion }}</p> -->
                <p class="fs-13 fw-600 black-text">{{ question.IsCorrect }}</p>
                <p class="fs-13 fw-600 black-text">{{ formatTime(question.TimeTakenInSec) }}</p>
                <p
                  class="fs-13 fw-600 skyblue-text pointer flex items-center"
                  @click="showSol(question.QuestionId)"
                >
                  <span
                    class="material-icons material-symbols-outlined pointer view-icon"
                    style="margin-right: 6px"
                    >visibility</span
                  ><span class="show-desktop-mode">Solution</span
                  ><span class="show-mobile-mode">Sol</span>
                </p>
              </div>
            </div>
            <div v-else class="text-center h-100 flex flex-column flex-center items-center">
              <img
                :src="require(`../../assets/no-result-found.png`)"
                alt=""
                style="width: 200px; opacity: 0.7"
              />
              <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">
                No question available for selected filter.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal :show="showQnsModal" @close="showQnsModal = false" :showHeader="false">
        <template v-slot:body>
          <div class="flex flex-between items-center">
            <p style="font-size: 25px; color: #642c90" class="fw-600 modal-text">Question</p>
            <i class="material-icons close-icon" @click="showQnsModal = false">clear</i>
          </div>
          <div class="divider TestLine-divide"></div>
          <div>
            <img
              class="responsive-img"
              draggable="false"
              :src="`${storage_Url}${qnsUrl}`"
              style="max-height: 450px"
            />
          </div>
        </template>
      </Modal>
      <ViewQuestionModal
        :questionId="modalQuestionId"
        :selectedTab="3"
        v-if="showSolModal"
        @closeModal="closeToggle($event)"
      />
    </div>
    <div v-else>
      <div class="valign-wrapper" style="height: 500px">
        <div class="valign">
          <div class="">
            <i class="material-icons medium amber-text text-darken-3">report</i>
            <br />
            <h4 class="light slcolor-text">{{ sessionSummary?.Title }}</h4>
            <br />
            <h5>This report will open on {{ formatDate(sessionSummary?.OpenDateTime) }}</h5>
            <br />
            <div>
              <a @click="goBackToPrevious()" class="btn waves-effect waves-light">BACK</a>
              <a @click="reload()" class="btn waves-effect waves-light" style="margin: 0px 20px"
                >REFRESH</a
              >
              <a @click="goHome()" class="btn waves-effect waves-light">HOME</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-css-donut-chart/dist/vcdonut.css";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { useToast } from "vue-toastification";
import moment from "moment";
import Modal from "../../components/Modal.vue";
import MobileApi from "../../Mobileapi";
import ViewQuestionModal from "../../components/ViewQuestionModal.vue";

const toast = useToast();

export default {
  data() {
    return {
      instituteTestUserId: null,
      sessionSummary: null,
      showFilterBox: false,
      showQuestionFilter: true,
      showSubFilter: true,
      showSortByFilter: true,
      qnsUrl: "",
      sections: [],
      showQnsModal: false,
      solUrl: "",
      showSolModal: false,
      testReportList: [],
      questionList: [],
      tempQnsList: [],
      filteredQnsList: [],
      filterSubjectList: [],
      subjectBreakdown: [],
      selectedSubjectId: -1,
      selectedQnsFilterId: -1,
      attemptedQnsCount: 0,
      correctQnsCount: 0,
      inCorrectQnsCount: 0,
      skippedQnsCount: 0,
      sortedQnsId: -1,
      currentSelectedFilter: 1,
      displayResult: false,
      type: null,
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  components: {
    CircleProgress,
    Modal,
    ViewQuestionModal,
  },
  created() {
    this.$store.dispatch("showLoader", true);
    this.instituteTestUserId = Number(this.$route.params.testUserId);
    this.type = Number(this.$route.params.testType);
    this.displayResult = true;
    const data = {
      testUserId: Number(this.$route.params.testUserId),
      testTypeId: Number(this.$route.params.testType),
    };
    setTimeout(() => {
      this.$store.dispatch("showLoader", true);
    }, 1000);
    MobileApi.getTestReport(data, (response) => {
      if (response.responseCode === 200 && Object.keys(response.data).length > 0) {
        this.testReportList = response.data;
        this.sessionSummary = this.testReportList.sessionSummary;
        this.displayResult = this.sessionSummary.ReportAvailable;
        const skippedPct =
          (this.sessionSummary.TotalSkipped / this.sessionSummary.TotalQuestions) * 100;
        const correctPct =
          (this.sessionSummary.TotalCorrect / this.sessionSummary.TotalQuestions) * 100;
        const wrongdPct =
          (this.sessionSummary.TotalWrong / this.sessionSummary.TotalQuestions) * 100;
        this.sections = [
          {
            value: skippedPct,
            color: "#E8E8E8",
          },
          {
            value: correctPct,
            color: "#00B894",
          },
          {
            value: wrongdPct,
            color: "#FF7675",
          },
        ];

        this.subjectBreakdown = this.testReportList.subjectBreakdown;
        this.questionList = this.testReportList.questions;
        this.tempQnsList = response.data.questions;
        this.filteredQnsList = response.data.questions;
        this.filterSubjectList = this.subjectBreakdown.map((item) => ({
          SubjectId: item.SubjectId,
          SubjectName: item.SubjectName,
        }));
        this.filterSubjectList.unshift({
          SubjectId: -1,
          SubjectName: "All",
        });

        this.attemptedQnsCount = this.questionList.filter(
          (item) => item.IsAttempted === true || item.IsAttempted === 1,
        ).length;

        this.correctQnsCount = this.questionList.filter(
          (item) => item.IsCorrect === true || item.IsCorrect === 1,
        ).length;
        this.inCorrectQnsCount = this.attemptedQnsCount - this.correctQnsCount;
        this.skippedQnsCount = this.questionList.length - this.attemptedQnsCount;
        this.$store.dispatch("showLoader", false);
      } else if (response.responseCode === 500) {
        toast.error(`${response.data.info}`, {
          timeout: 2500,
        });
        this.testReportList = [];
        this.$store.dispatch("showLoader", false);
      } else {
        toast.error("Report not available", {
          timeout: 2500,
        });
        this.testReportList = [];
        this.showQuestionFilter = false;
        this.showSubFilter = false;
        this.showSortByFilter = false;
        this.$store.dispatch("showLoader", false);
      }
    });
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY hh:mm:ss A");
      }
      return null;
    },
    reload() {
      window.location.reload();
    },
    goHome() {
      this.$router.push({
        name: "StudentDashboard",
      });
    },
    goBackToPrevious() {
      if (this.type === 1) {
        this.$store.dispatch("showLoader", true);
        this.$router.push({
          name: "SelfTestHistoryNew",
        });
        this.$store.dispatch("showLoader", false);
      } else if (this.type === 2) {
        this.$store.dispatch("showLoader", true);
        this.$router.push({
          name: "SelectInstituteTest",
        });
        this.$store.dispatch("showLoader", false);
      } else if (this.type === 3) {
        this.$store.dispatch("showLoader", true);
        this.$router.push({
          name: "SharedTestListNew",
        });
        this.$store.dispatch("showLoader", false);
      } else if (this.type === 4) {
        this.$store.dispatch("showLoader", true);
        this.$router.push({
          name: "StudentDashboard",
        });
        this.$store.dispatch("showLoader", false);
      }
    },
    showQns(qnsUrl) {
      this.qnsUrl = qnsUrl.replace("~", "");
      this.showQnsModal = true;
    },
    showSol(QuestionId) {
      this.modalQuestionId = QuestionId;
      this.showSolModal = true;
    },
    oprnFilter() {
      this.showFilterBox = !this.showFilterBox;
    },
    formatTime(value) {
      if (value) {
        const hour = Math.floor(value / 3600);
        const minute = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60) || "";
        if (value > 59) {
          return (
            (hour >= 1 ? ` ${hour}h` : "") +
            (minute >= 1 ? ` ${minute}m` : "") +
            (seconds >= 1 ? ` ${seconds}s` : "")
          );
        }
        return `${Math.floor(value)}s`;
      }
      return "0s";
    },
    filterQnsList(filterId) {
      this.currentSelectedFilter = filterId;
      let filteredQnsList = [...this.tempQnsList];
      switch (filterId) {
        case 1:
          filteredQnsList = this.tempQnsList;
          break;
        case 2:
          filteredQnsList = filteredQnsList.filter(
            (item) => item.IsAttempted === true || item.IsAttempted === 1,
          );
          break;
        case 3:
          filteredQnsList = filteredQnsList.filter(
            (item) => item.IsAttempted && (item.IsCorrect === true || item.IsCorrect === 1),
          );
          break;
        case 4:
          filteredQnsList = filteredQnsList.filter((item) => item.IsAttempted && !item.IsCorrect);
          break;
        case 5:
          filteredQnsList = filteredQnsList.filter((item) => !item.IsAttempted);
          break;
        default:
          break;
      }
      this.filteredQnsList = filteredQnsList;
      this.questionList = filteredQnsList;
      this.selectedQnsFilterId = -1;
      this.selectedSubjectId = -1;
      this.sortedQnsId = -1;
    },
    filterByQns(event) {
      this.selectedQnsFilterId = Number(event.target.value);
      this.toggleFilter();
    },
    filterBySubject(event) {
      this.selectedSubjectId = Number(event.target.value);
      this.toggleFilter();
    },
    sortQnsList(event) {
      this.sortedQnsId = Number(event.target.value);
      this.toggleFilter();
    },
    toggleFilter() {
      let filteredQuestionList = [...this.filteredQnsList];
      this.questionList = [];
      if (this.selectedQnsFilterId !== -1) {
        switch (this.selectedQnsFilterId) {
          case 1:
            filteredQuestionList = filteredQuestionList.filter(
              (qns) => qns.IsCorrect === 1 && qns.IsAttempted === Boolean(1),
            );
            break;
          case 2:
            filteredQuestionList = filteredQuestionList.filter(
              (qns) => qns.IsCorrect === 0 && qns.IsAttempted === Boolean(1),
            );
            break;
          case 3:
            filteredQuestionList = this.tempQnsList.filter((qns) => qns.IsAttempted === Boolean(0));
            break;
          default:
            break;
        }
      }
      if (this.selectedSubjectId !== -1) {
        filteredQuestionList = filteredQuestionList.filter(
          (qns) => qns.SubjectId === this.selectedSubjectId,
        );
      }
      if (this.sortedQnsId !== -1) {
        switch (this.sortedQnsId) {
          case 1:
            filteredQuestionList = filteredQuestionList.sort(
              (a, b) => a.TimeTakenInSec - b.TimeTakenInSec,
            );
            break;
          case 2:
            filteredQuestionList = filteredQuestionList.sort(
              (a, b) => b.TimeTakenInSec - a.TimeTakenInSec,
            );
            break;
          default:
            break;
        }
      }
      if (filteredQuestionList.length > 0) {
        this.questionList = filteredQuestionList;
      } else if (
        this.selectedQnsFilterId === -1 &&
        this.selectedSubjectId === -1 &&
        this.sortedQnsId === -1
      ) {
        this.questionList = this.filteredQnsList;
      }
      this.showFilterBox = false;
    },
    closeToggle(event) {
      this.showSolModal = event;
    },
  },
  computed: {
    storage_Url() {
      return process.env.VUE_APP_SL_URL;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.dark-text {
  color: #333;
}

.skyblue-text {
  color: #3751ff;
}

.skyblue-light {
  color: #5c8eff;
}

.fs-42 {
  font-size: 42px;
}

.fs-26 {
  font-size: 26px;
}

.fs-36 {
  font-size: 36px;
}

.test-report {
  margin-top: 20px;
  margin-bottom: 20px;
}

.top-left,
.report-top {
  display: flex;
  gap: 20px;
  /* height: 356px; */
}

.result-box {
  width: 232px;
  height: 108px;
  background: linear-gradient(180deg, #85abfa 0%, #6e92f6 100%);
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  padding: 16px 0 0 20px;
}

.result-box::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  bottom: -13px;
  right: -9px;
  background: url("../../assets/score12.svg") no-repeat;
  background-position: bottom right;
  opacity: 0.4;
}

.result-box:nth-child(2) {
  margin: 16px 0;
}

.result-box:nth-child(2)::after {
  background: url("../../assets/attempt14.svg") no-repeat !important;
  right: -14px;
  bottom: -20px;
  opacity: 1;
}

.result-box:nth-child(3)::after {
  background: url("../../assets/correct14.svg") no-repeat !important;
  bottom: -2px;
  right: -7px;
}

.score span:first-child {
  position: relative;
  top: -10px;
  font-family: "Inter", sans-serif;
}

.ff-inter {
  font-family: "Inter", sans-serif;
}

.score span:last-child {
  position: relative;
  top: -8px;
  left: -10px;
  font-family: "Inter", sans-serif;
}

.top-header {
  position: relative;
  color: #333;
}

.top-header::before {
  position: absolute;
  content: "";
  top: 0;
  left: -10px;
  width: 4px;
  height: 100%;
  background: #5c8eff;
  border-radius: 0 2px 2px 0px;
}

.sub-detail {
  padding-left: 20px;
  margin-top: 27px;
}

.sub-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 10px;
}

.sub-content {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.right-accuracy {
  position: relative;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 10px;
  min-width: 390px;
  max-width: 100%;
  border: 1px solid #e0e4f0;
}

.right-accuracy::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 97%;
  bottom: 0;
  left: 0;
  background: #fff url("../../assets/wave-bkg.svg") no-repeat;
  border-radius: 5px;
}

.accuracy-detail:nth-child(2) {
  margin: 14px 0;
}

.accuracy-detail::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  top: 2px;
  left: -20px;
  background-color: #00b894;
  border-radius: 50%;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 184, 148, 0.3);
}

.accuracy-detail:nth-child(2):before {
  background-color: #ff7675;
  box-shadow: 0px 4px 6px rgba(255, 118, 117, 0.3);
}

.accuracy-detail:nth-child(3):before {
  background-color: #e8e8e8;
  box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.3);
}

.donut-box {
  position: absolute;
  right: 30px;
  margin-top: 15px;
}

.top-right {
  padding: 10px;
  /* width: 555px; */
  width: calc(100% - 642px);
  border-radius: 5px;
  border: 1px solid #e0e4f0;
}

.progress-bar {
  position: relative;
  width: 80%;
  height: 6px;
  background: #eaeaea;
  border-radius: 10px;
}

.progress-meter {
  position: absolute;
  background-color: #f7823c;
  height: 100%;
  border-radius: 10px;
}

.report-bottom {
  margin-top: 21px;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
}

.bottom-top {
  gap: 95px;
  border-bottom: 1px solid #e0e4f0;
  padding: 35px 15px 20px 15px;
  border-radius: 5px 5px 0px 0px;
}

.filter-section {
  width: 214px;
  border-right: 1px solid #e0e4f0;
  border-radius: 0 0 0 5px;
}

.filter {
  padding: 20px 14px;
  border-bottom: 1px solid #e0e4f0;
}

.options {
  margin-top: 15px;
}

.options p {
  margin: 15px 0;
}

.options p label {
  color: #000000;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #3751ff;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #3751ff;
}

.content-section {
  width: calc(100% - 214px);
  padding: 10px 15px;
  border-bottom: 30px solid white;
  border-radius: 0 0 5px;
}

.content-body {
  max-height: 700px;
}

.content-header,
.contents {
  display: grid;
  grid-template-columns: 3% 38% 14% 9% 9% 7%;
  align-items: center;
  grid-template-rows: auto;
  grid-gap: 25px;
  align-items: center;
}

.contents {
  border-radius: 5px;
  border: 1px solid #e0e4f0;
  height: 77px;
  margin-top: 10px;
}

.qns-img {
  width: 100%;
  height: 47px;
  overflow: hidden;
  border: 1px solid #e0e4f0;
  cursor: pointer;
  border-radius: 3px;
}

.show-mobile-mode {
  display: none !important;
}

.view-icon {
  font-size: 18px;
}

.close-icon {
  width: 25px;
  height: 25px;
  color: white;
  background: #ff7675;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 1px;
  border-radius: 5px;
}

.disable-filters {
  pointer-events: none;
}

.qns-filter.active::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 100%;
  background: #5c8eff;
  bottom: -20px;
  left: 0;
  border-radius: 5px 5px 0px 0px;
}

/* Media Query */
@media screen and (max-width: 1270px) {
  .test-report {
    padding: 0 20px;
  }

  .report-top {
    flex-direction: column;
  }

  .right-accuracy,
  .top-right {
    width: 100%;
  }

  .content-header,
  .contents {
    grid-template-columns: 3% 30% 14% 9% 9% 10%;
    grid-gap: 15px;
  }

  .bottom-top {
    gap: 35px;
  }

  .breakdown-list {
    max-height: 255px;
    height: 100% !important;
  }
}

@media screen and (max-width: 750px) {
  .report-filters {
    position: relative;
    overflow: hidden;
  }

  .filter-section {
    position: absolute;
    width: 140px;
    left: -100%;
    height: 100%;
    transition: 0.5s all ease;
  }

  .filter-section.open-filter {
    left: 0;
    transition: 0.5s all ease;
  }

  .content-section {
    width: 100%;
  }

  .content-header,
  .contents {
    grid-template-columns: 3% 35% 14% 10% 9% 10%;
    grid-gap: 10px;
  }

  .filter-icon {
    background: white;
    padding: 2px;
    border-radius: 3px;
    border: 1px solid rgb(224, 228, 240);
    width: 30px;
  }
}

@media screen and (max-width: 650px) {
  .left-result,
  .result-box {
    width: 100%;
  }

  .top-left {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .right-accuracy {
    height: 370px;
  }

  .show-desktop-mode {
    display: none !important;
  }

  .show-mobile-mode {
    display: block !important;
  }

  .qns-img {
    height: auto;
    border: none;
  }

  .content-header,
  .contents {
    grid-template-columns: 5% 15% 15% 15% 15% 9%;
    grid-gap: 20px;
  }

  .view-icon {
    font-size: 16px;
  }

  .sub-header p:nth-child(2) {
    margin-left: -50px;
  }

  .sub-header,
  .sub-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    flex-wrap: wrap;
  }

  .modal-text {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 500px) {
  .content-header,
  .contents {
    grid-template-columns: 3% 15% 17% 15% 15% 9%;
    grid-gap: 10px;
  }
}

@media screen and (max-width: 460px) {
  .content-section {
    width: 420px;
    padding: 5px;
  }

  .content-header,
  .contents {
    grid-template-columns: 10% 21% 15% 12% 12% 8%;
    grid-gap: 6px;
  }

  .answer-header {
    white-space: break-spaces !important;
  }
}

@media screen and (max-width: 420px) {
  .right-accuracy {
    min-width: 300px;
    height: 430px;
  }

  .right-accuracy::before {
    height: 78%;
  }

  .donut-box {
    top: 130px;
  }

  .progress-bar {
    width: 100px;
  }

  .sub-header p:nth-child(2) {
    margin-left: -30px;
  }
}
</style>
